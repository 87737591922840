import React, { useEffect, useState } from 'react';
import config from "../config/default.json";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useTournament } from './TournamentContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';


const base_url = config.base_url;


export default function Contact() {
    const [result, setResult, setValue] = React.useState("");
  
    const onHCaptchaChange = (token) => {
      setValue("h-captcha-response", token);
    };

    const onSubmit = async (event) => {
      event.preventDefault();
      setResult("Sending....");
      const formData = new FormData(event.target);
  
      formData.append("access_key", "ef530cd9-b677-4506-bc17-cedf4e0a4fe6");
  
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      });
  
      const data = await response.json();
  
      if (data.success) {
        setResult("Request Submitted Successfully");
        alert('Request Submitted Successfully');
        event.target.reset();
      } else {
        console.log("Error", data);
        setResult(data.message);
        alert('Error ' + result);
      }
    };
  
    return (
      <div className="feedback-container">

        <h1>  <FontAwesomeIcon icon={faComments} size="1x"/> &nbsp; Contact </h1>
      <br/>
      <h3> We are working on building user logon based portal to allow creating tournament and be 
          able to manage them meanwhile please use the form below to request hosting
           your tournament and we will follow up to get you started. </h3>
      <br />
        <br/>

        <section className="contact"> 
        <form onSubmit={onSubmit} className="contact-from">
            <h2>Contact Form</h2>

        <div className="input-box">
            <label>Full Name</label>
            <input type="text" className="field" name="name" placeholder='enter your name' required/>
        </div>

        <div className="input-box">
            <label>Email</label>
            <input type="email" className="field" name="email" placeholder='enter your email' required/>
        </div>

        <div className="input-box">
            <label>Tournament Details</label>
            <input type="message" className="field message" name="message" placeholder='provide details of your tournament. Name, Acronym, Year, Location etc.' required/>
        </div>

        <input type="checkbox" name="botcheck" class="hidden" style={{display:"none"}}></input>
        <br/>
          <HCaptcha
            sitekey="50b2fe65-b00b-4b9e-ad62-3ba471098be2"
            reCaptchaCompat={false}
            onVerify={onHCaptchaChange} 
          /> 

          <button type="submit">Submit Request</button>
        </form>
        </section>
        <span>{result}</span>
  
      </div>
    );
  }
  