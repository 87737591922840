import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from "../config/default.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { useTournament } from './TournamentContext';

//import './../styles.css'; // Import your CSS file

const base_url = config.base_url;

const AdminFeedback = () => {
  const [feedbackText, setFeedbackText] = useState('');
  const [name, setName] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  const [feedbackList, setFeedbackList] = useState([]);
  const { tournamentID } = useTournament();


  useEffect(() => {
    if(tournamentID){
      fetchFeedback();
    }
  }, [tournamentID]);

  const fetchFeedback = async () => {
    try {
      const response = await axios.get( `http://192.168.1.45:3333/api/adminfeedback/`);
      setFeedbackList(response.data);
    } catch (error) {
      console.error('Failed to fetch feedback', error);
    }
  };



  return (
    <div className="feedback-container">
      <h1>  <FontAwesomeIcon icon={faComments} size="1x"/> &nbsp; Admin Feedback</h1>
      <br/>
     <br />
      <div className="feedback-list">
        <h2>Feedback Received:</h2>
        {feedbackList.map((feedback, index) => (
          <div key={index} className="feedback-card">
             <p className="feedback-name"> Private :{feedback.isPrivate.toString()}</p>
            <p className="feedback-name">{feedback.name || 'Anonymous'}:</p>
            <p className="feedback-text">{feedback.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminFeedback;
